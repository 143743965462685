import { RightTableGrid } from '@/components/Shared';
import React from 'react';
import { IHomeSection } from '@/interfaces/category';
import format from '@/utils/format';
import { Image_Subtypes_Enum, useGetBannerQuery } from '@/generated/graphql';
import { GetBannerHomepageParams } from '@/constants/defaultParams';
import { BannerCustomizeHeight } from '../Shared';

const HomeRightSection = ({ data }: IHomeSection) => {
  const dataPost1 = format.formatDataPost(data?.[0]?.allPosts);
  const category1 = format.formatDataCategory(
    data?.[0]?.children,
    data?.[0]?.pathFullSlug?.fullSlug || '',
    data?.[0]?.name || '',
  );
  const dataPost2 = format.formatDataPost(data?.[1]?.allPosts);
  const category2 = format.formatDataCategory(
    data?.[1]?.children,
    data?.[1]?.pathFullSlug?.fullSlug || '',
    data?.[1]?.name || '',
  );
  const dataPost3 = format.formatDataPost(data?.[2]?.allPosts);
  const category3 = format.formatDataCategory(
    data?.[2]?.children,
    data?.[2]?.pathFullSlug?.fullSlug || '',
    data?.[2]?.name || '',
  );

  const { data: dataBanner } = useGetBannerQuery({
    variables: GetBannerHomepageParams,
  });

  return (
    <div className="flex flex-col gap-6">
      <div>
        <BannerCustomizeHeight
          data={dataBanner?.banners.filter(
            item => item.position === Image_Subtypes_Enum.HomeRight_1,
          )}
          priority
        />
      </div>
      {dataPost1 && (
        <RightTableGrid key={'x'} category={category1} data={dataPost1} />
      )}
      <div>
        <BannerCustomizeHeight
          data={dataBanner?.banners.filter(
            item => item.position === Image_Subtypes_Enum.HomeRight_2,
          )}
        />
      </div>
      {dataPost2 && (
        <RightTableGrid key={'y'} category={category2} data={dataPost2} />
      )}
      {dataPost3 && (
        <RightTableGrid key={'z'} category={category3} data={dataPost3} />
      )}
      <div>
        <BannerCustomizeHeight
          data={dataBanner?.banners.filter(
            item => item.position === Image_Subtypes_Enum.HomeRight_3,
          )}
        />
      </div>

      {data?.slice(3)?.map((category, index) => {
        const dataPost = format.formatDataPost(category.allPosts);
        const dataCategory = format.formatDataCategory(
          category?.children,
          category?.pathFullSlug?.fullSlug || '',
          category?.name || '',
        );
        return (
          <RightTableGrid key={index} category={dataCategory} data={dataPost} />
        );
      })}
    </div>
  );
};

export default HomeRightSection;
